import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "apollo-link-context"
import fetch from "isomorphic-fetch"

const cache = new InMemoryCache()

export const client = new ApolloClient({
    uri: `${process.env["GATSBY_GRAPHQL_URL"]}`,
    cache: new InMemoryCache(),
    // cache,
    // fetch,
    // link: ApolloLink.from([
    //     setContext((request, prev) => {
    //         return {
    //             headers: {
    //                 ...prev.headers,
    //                 Authorization: `Bearer ${request.variables.revision_id}:${request.variables.token}`,
    //             },
    //         }
    //     }),
    //     new HttpLink({
    //         uri: `${process.env["GATSBY_GRAPHQL_URL"]}`,
    //     }),
    // ]),
})
