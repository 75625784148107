import React, { createContext, useState } from "react"

const defaultValues = {
    allFilters: {},
}

const WhatsOnContext = createContext(defaultValues)

const WhatsOnContextProvider = ({ children }) => {
    const [allFilters, setAllFilters] = useState({})
    const [whatsOnDate, setWhatsOnDate] = useState("")

    return <WhatsOnContext.Provider value={{ allFilters, setAllFilters, whatsOnDate, setWhatsOnDate }}>{children}</WhatsOnContext.Provider>
}

export { WhatsOnContext, WhatsOnContextProvider }
