import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
import { WhatsOnContextProvider } from "../context/WhatsOnContext"
// import { ObjectContextProvider } from "../context/ObjectContext"

export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={client}>
        <WhatsOnContextProvider>{element}</WhatsOnContextProvider>
        {/* <ObjectContextProvider>{element}</ObjectContextProvider> */}
    </ApolloProvider>
)
